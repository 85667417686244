import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../sections/home"
import About from "../sections/about"
import Games from "../sections/games"
import Contact from "../sections/contact"
import ReactPageScroller from "react-page-scroller"
import Nav from "../components/nav"
import { Pager } from "react-bootstrap"
import "./index.scss"

import style from "./style.module.scss"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: null,
      isSelected: false,
    }
  }

  getPagesNumbers = () => {
    const { isActive, navElements } = style
    const pageNumbers = []
    const pagesNames = ["home", "About", "lucky buddies", "contact"]
    for (let i = 1; i <= 4; i++) {
      let currentPage = this.state.currentPage
      pageNumbers.push(
        <Pager.Item
          key={i}
          className={`${navElements} ${i - 1 === currentPage ? isActive : ""}`}
          eventKey={i - 1}
          onSelect={this.handlePageChange}
        >
          {pagesNames[i - 1]}
        </Pager.Item>
      )
    }

    return [...pageNumbers]
  }

  goToPage = pageNumber => {
    this.reactPageScroller.goToPage(pageNumber)
  }

  handlePageChange = number => {
    this.setState(prevState => ({
      currentPage: number,
    }))
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClick, false)
  }

  render() {
    const pagesNumbers = this.getPagesNumbers()
    return (
      <Layout>
        <SEO title="EVERYBUDDY LTD" />
        <Nav currentPage={this.state.currentPage}>{pagesNumbers}</Nav>
        <ReactPageScroller
          ref={c => (this.goToPage = c)}
          customPageNumber={this.state.currentPage}
          pageOnChange={this.handlePageChange}
          animationTimer={1000}
          renderAllPagesOnFirstRender={true}
          transitionTimingFunction={"cubic-bezier(0.4, 0.0, 0.2, 1)"}
        >
          <Home />
          <About setId={"About"} />
          <Games setId={"Games"} />
          <Contact setId={"Contact"} />
        </ReactPageScroller>
      </Layout>
    )
  }
}

export default IndexPage
