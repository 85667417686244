import React from "react"
import SectionLayout from "../components/sectionLayout"
import bg from "../assets/img/buddiesCover.png"
import buddies from "../assets/img/buddies.png"
import buddiesLogo from "../assets/img/buddiesLogo.png"
import style from "./game.module.scss"
import { useInView } from "../Hooks/InView"
import google from "../assets/img/google-play-badge.svg"
import appStore from "../assets/img/app-store-badge.svg"
import BuddiesPic from "../assets/img/buddiesPic.png"
import { Link } from "gatsby"
const Games = ({ setId }) => {
  const {
    cover,
    gameWrapper,
    buddiesImg,
    isVisibleClass,
    buddiesLogoImg,
    buttonsWrapper,
    BuddiesPicWrapper,
    BuddiesPicCont,
  } = style
  const [setRef, isVisible] = useInView({ threshold: 0.5 })
  return (
    <SectionLayout>
      <div id={setId} className={gameWrapper}>
        <Link
          className={`${BuddiesPicWrapper}`}
          to={"https://www.luckybuddies.com/"}
        >
          <div
            ref={setRef}
            className={`${BuddiesPicCont} ${
              isVisible ? `${isVisibleClass}` : ""
            }`}
            style={{
              backgroundImage: `url(${BuddiesPic})`,
            }}
          ></div>
        </Link>
        <div className={buttonsWrapper}>
          <Link
            to={
              "https://play.google.com/store/apps/details?id=air.com.wizits.buddies&hl=en"
            }
          >
            <img src={google} />
          </Link>
          <Link to={"https://apps.apple.com/ph/app/lucky-buddies/id1446723780"}>
            <img src={appStore} />
          </Link>
        </div>
        {/* 
        <button>
          <a href="https://www.luckybuddiesgame.com/" target="_blank">
            discover
          </a>
        </button>
       
        <img
          ref={setRef}
          className={`${buddiesLogoImg} ${
            isVisible ? `${isVisibleClass}` : ""
          }`}
          src={buddiesLogo}
          alt={"buddiesLogo"}
        />
        <img
          ref={setRef}
          className={`${buddiesImg} ${isVisible ? `${isVisibleClass}` : ""}`}
          src={buddies}
          alt={"buddies"}
        />
        */}
        <div
          className={cover}
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
      </div>
    </SectionLayout>
  )
}

export default Games
