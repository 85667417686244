import React from "react"
import PropTypes from "prop-types"
import style from "./sectionLayout.module.scss"
import { useInView } from "../Hooks/InView"

const SectionLayout = ({ children }) => {
  const { container, isVisibleClass } = style

  const [setRef, isVisible] = useInView({ threshold: 0.5 })

  return (
    <div className={`${container} ${isVisible ? `${isVisibleClass}` : ""}`}>
      <div className={container}>{children}</div>
    </div>
  )
}

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionLayout
