import React from "react"
import SectionLayout from "../components/sectionLayout"
import style from "./about.module.scss"
import bg from "../assets/img/viking.png"
import { useInView } from "../Hooks/InView"
const About = ({ setId }) => {
  const { textWrapper, img, aboutWrapper, imgContainer, isVisibleClass } = style
  const [setRef, isVisible] = useInView({ threshold: 0.5 })
  return (
    <SectionLayout>
      <div id={setId} className={aboutWrapper}>
        <div className={imgContainer}>
          <div
            ref={setRef}
            className={`${img} ${isVisible ? `${isVisibleClass}` : ""}`}
            style={{
              backgroundImage: `url(${bg})`,
            }}
          ></div>
        </div>
        <div className={textWrapper}>
          <h1>We Live</h1>
          <h1>Our Games.</h1>
          <p>
            We are a team of gaming enthusiasts with years of experience in the
            industry. Based in Tel Aviv, the gaming capital of the world, we are
            constantly evolving, creating an awesome gaming experience spreading
            joy and entertainment to our growing audience around the globe.
          </p>
          <p>
            We believe in constant production innovation, always striving to
            stay ahead of the game.
          </p>
        </div>
      </div>
    </SectionLayout>
  )
}

export default About
