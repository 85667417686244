import { useState, useEffect } from "react"

export const useInView = options => {
  const [ref, setRef] = useState(null)
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting)
      })
    }, options)

    if (ref) {
      observer.observe(ref)
    }
  }, [setRef, options])

  return [setRef, isVisible]
}
