import React from "react"
import style from "./contact.module.scss"
import Facebook from "../assets/img/facebook.png"
import Linkedin from "../assets/img/linkedin.png"
import { Link } from "gatsby"

const Contact = ({ setId }) => {
  const {
    contactWrapper,
    texttWrapper,
    socialMediaIconsWrapper,
    iconsWrapper,
  } = style
  return (
    <div id={setId} className={contactWrapper}>
      <div className={texttWrapper}>
        <h1>Let's Be In Touch</h1>
      </div>
      <div className={socialMediaIconsWrapper}>
        <p>info@everybuddy.com</p>
        <div className={iconsWrapper}>
          <a href="https://www.facebook.com/everybuddygames/" target="_blank">
            <img src={Facebook} alt="facebook social icon" />
          </a>
          <a href="https://www.linkedin.com/company/everybuddy/about/" target="_blank">
            <img src={Linkedin} alt="linkedin social icon" />
          </a>
        </div>
        <span>for security vulnerability reporting please mail security@everybuddygames.com</span>
        <span>
          ©2020 everybuddy Ltd. All Rights Reserved |{" "}
          <Link to="/privacyTerms">Privacy Policy</Link> |{" "}
          <Link to="/EndUserLicenseAgreement">Terms and Conditions </Link>
        </span>
      </div>
    </div>
  )
}

export default Contact
