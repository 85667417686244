import React from "react"
import SectionLayout from "../components/sectionLayout"
import style from "./home.module.scss"
import bg from "../assets/img/headerPic.png"
import { useInView } from "../Hooks/InView"
const Home = () => {
  const { homeSection, img, textWrap, isVisibleClass } = style
  const [setRef, isVisible] = useInView({ threshold: 0.5 })
  return (
    <SectionLayout>
      <div className={homeSection}>
        <div className={`${textWrap} ${isVisible ? `${isVisibleClass}` : ""}`}>
          <h1>WE ARE</h1>
          <h1>EVERYBUDDY</h1>
        </div>
        <div
          ref={setRef}
          className={`${img} ${isVisible ? `${isVisibleClass}` : ""}`}
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
      </div>
    </SectionLayout>
  )
}

export default Home
