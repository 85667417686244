import React from "react"
import style from "./nav.module.scss"
import Logo from "../assets/img/logo_white.png"

const Nav = ({ currentPage, children }) => {
  const { menuWrapper, logoWrapper, logo, highlight, mobileLogoWrapper } = style

  return (
    <>
      <nav className={`${currentPage !== 0 ? highlight : ""}`}>
        <div className={`${logoWrapper}`}>
          <img className={`${logo}`} src={Logo} />
        </div>
        <ul className={`${menuWrapper}`}>{children}</ul>
      </nav>

      <div
        className={`${mobileLogoWrapper} ${currentPage !== 0 ? highlight : ""}`}
      >
        <img className={`${logo}`} src={Logo} />
      </div>
    </>
  )
}

export default Nav
